@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

$mq-tablet: 768px !default;

// Everything below and including the portrait width of the tablet
@mixin tablet {
  @media only screen and (max-width : $mq-tablet) {
    @content;
  }
}

.controls-container {
  display: block;
  position: relative;
  z-index: 500;
}

.map-object-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include tablet {
    top: 100px;

    &.active {
      z-index: 0;
    }
  }
}

.loading-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results-flag {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: .8em;
  margin-bottom: 0;
  padding-bottom: 0;
}

.filter-active-flag {
  text-align: center;
  margin-top: 5px;

  @include tablet {
    display: none;

    &.active {
      display: block;
    }
  }
}

.button-type {
  margin-right: 15px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: .8em;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.logoContainer {
  position: absolute;
  top: 42px;
  left: 30px;
  width: 340px;
  height: auto;
  z-index: 500;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }

  @include tablet {
    top: 20px;
    width: 150px;
  }
}

.appContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.mapboxgl-map {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.legendContainer {
  position: absolute;
  top: 150px;
  left: 30px;
  z-index: 300;
  display: block;
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  @include tablet {
    top: 5px;
    right: 0;
    left: auto;
    padding-right: 10px;
  }

  hr {
    border-width: 0;
    height: 2px;
    background-color: #0b56a4;
    margin: 1px 0;

    @include tablet {
      display: none;

      &.active {
        display: flex;
      }
    }
  }

  .mobile-filter-toggle {
    display: none;
    margin: 0;
    padding: 10px;
    position: relative;
    cursor: pointer;

    @include tablet {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    span {
      margin-right: 15px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 1em;
    }

    svg {
      width: 20px;
    }
  }

  .search-input {
    border: 1px solid lightgray;
    margin: 0 0 25px;
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;

    @include tablet {
      display: none;
      margin-top: 30px;

      &.active {
        display: flex;
      }
    }

    svg {
      width: 15px;
    }

    input {
      margin: 0;
      border-width: 0;
      outline: none;
      width: 100%;
      margin-left: 5px;

      &:hover,
      &:focus,
      &:active {
        border-width: 0;
        outline: none;
      }
    }
  }

  .level-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: space-between;
    align-items: flex-start;

    @include tablet {
      display: none;

      &.active {
        display: flex;
      }
    }

    .level-itm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 29%;
      margin-top: 5px;
      margin-bottom: 13px;
      text-align: center;
      text-transform: uppercase;
      font-size: .8em;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;

      .level-title {
        background-color: #0b56a4;
        border-radius: 10px;
        cursor: pointer;

        p {
          margin: 6px 0;
          color: white;
        }
      }

      .level-description {

        p {
          margin-bottom: 0;
        }
      }

      &.not-selected {

        .level-title {
          opacity: .5;
        }
      }
    }
  }
}

.categoryContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;

  @include tablet {
    display: none;

    &.active {
      display: flex;
    }
  }

  .category-itm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;
    margin-top: 5px;
    margin-bottom: 13px;

    &.not-selected {

      .category-icon {
        opacity: .2;
      }

      .category-title {
        opacity: .5;
      }
    }
  }

  .category-icon,
  .category-title {
    text-align: center;
    cursor: pointer;
  }

  .category-icon {

    img {
      width: 60px;
      height: 60px;
    }
  }

  .category-title {

    p {
      margin: 0;
      text-transform: uppercase;
      font-size: .8em;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
  }
}

.map-popup-container {
  z-index: 900 !important;
  border: 1px solid lightgrey;
  border-radius: 10px;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    border-radius: 10px;
    background: url('/images/popup/stripe.png') no-repeat right bottom;
    background-size: 120px;
    background-color: #ffffff;
    min-width: 200px;
    min-height: 200px;
  }

  .close-marker {
    position: absolute;
    top: -8px;
    right: -8px;

    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .marker-header {
    position: relative;

    .location-title {
      color: #0054ac;
      font-family: 'Arvo', serif;
      font-size: 2em;
      margin: 0;
      padding: 20px 70px 20px 20px;
    }

    .location-icon {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      position: absolute;
      top: 13px;
      right: 20px;
      margin: 0;
      padding: 0;

      img {
        max-width: 35px;
      }
    }
  }

  .marker-container {
    display: flex;
    max-width: 600px;

    .marker-content-right,
    .marker-content-left {

      p {
        font-size: 1.1em;
        color: #5A5A5A;
      }

      a,
      a {
        color: #0054ac;
        text-decoration: none;
      }
    }

    .marker-content-left {
      padding: 20px;

      .IconSvgImage {
        width: 18px;
        margin-right: 7px;
        fill: #f9921b;
        color: #f9921b;
      }
    }

    .marker-content-right {

      .marker-image {
        padding: 20px 20px 0;
        margin: 0;
        line-height: 0;
      }

      .marker-image img {
        max-width: 100%;
      }

      .copy-container {
        padding: 0 20px 20px;
      }
    }

    .marker-location {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
